<template>
  <b-modal
      :id="modalId"
      hide-header
      hide-footer
      hide-header-close
      size="lg"
      header-class="my-second-class"
      :body-bg-variant="'gray-lighten'"
  >
    <b-row>
      <b-col align="center">
        <div class="modal-title-class">
          <label class="text-danger-light">
            {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.title') }}
          </label>
        </div>
      </b-col>
    </b-row>
    <template>
      <b-row>
        <b-col cols="1"></b-col>
        <b-col>
          <b-row no-gutters class="mt-2">
            <b-row class="mt-3 w-100">
              <b-col class="gray-col-title" cols="2">
                {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.none.title')}}
              </b-col>
              <b-col cols="9" class="black-col-title">
                {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.none.description') }}
              </b-col>
            </b-row>
            <b-row class="mt-3 w-100">
              <b-col class="gray-col-title" cols="2">
                {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.flexible.title') }}
              </b-col>
              <b-col cols="9" class="black-col-title">
                {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.flexible.description') }}
              </b-col>
            </b-row>
            <b-row class="mt-3 w-100">
              <b-col class="gray-col-title" cols="2">
                {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.strict.title') }}
              </b-col>
              <b-col cols="9" class="black-col-title">
                {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.strict.description') }}
              </b-col>
            </b-row>
            <b-row class="mt-3 w-100">
              <b-col class="gray-col-title" cols="2">
                {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.custom.title') }}
              </b-col>
              <b-col cols="9" class="d-flex black-col-title">
                {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.custom.description-start') }}
                <b-form-input disabled style="width: 8%" size="sm" value="XX" class="h-75  text-center background-light-blue-inputs ml-1 mr-1"> </b-form-input>
                {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.custom.description-end') }}
              </b-col>
            </b-row>
          </b-row>
        </b-col>
      </b-row>
    </template>
    <div class="border-bottom-grey-dark mt-3 mb-3"/>
    <b-row>
      <b-col align="center">
        <d-button
            :text="'general.actions.dismiss'"
            :class="'d-btn btn-full-width btn d-btn-danger font-text-title'"
            @on:button-click="$bvModal.hide(modalId)"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
export default {
  data: () => ({
    btnSwitch: true,
    active: false,
    componentFormKey: 1,
  }),
  props: {
    modalId: {
      type: String,
      default: 'default'
    },
    display: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    display: function (changes) {
      this.$bvModal.show(this.modalId);
    },
    hide: function (changes) {
      this.$bvModal.hide(this.modalId);
    }
  },
}
</script>
<style scoped>
.modal-title-class {
  font: normal normal 500 25px Avenir;
  letter-spacing: 0px;
  color: #3C3D43;
  opacity: 1;
}


/deep/ .modal {
  padding-top: 40px
}

/deep/ .modal-content {
  border-radius: 8px;
}
/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}

</style>
